exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-cms-vs-custom-built-website-js": () => import("./../../../src/pages/blog/cms-vs-custom-built-website.js" /* webpackChunkName: "component---src-pages-blog-cms-vs-custom-built-website-js" */),
  "component---src-pages-blog-features-of-a-modern-website-js": () => import("./../../../src/pages/blog/features-of-a-modern-website.js" /* webpackChunkName: "component---src-pages-blog-features-of-a-modern-website-js" */),
  "component---src-pages-blog-how-the-website-creation-process-works-js": () => import("./../../../src/pages/blog/how-the-website-creation-process-works.js" /* webpackChunkName: "component---src-pages-blog-how-the-website-creation-process-works-js" */),
  "component---src-pages-blog-how-to-choose-the-best-hosting-and-domain-js": () => import("./../../../src/pages/blog/how-to-choose-the-best-hosting-and-domain.js" /* webpackChunkName: "component---src-pages-blog-how-to-choose-the-best-hosting-and-domain-js" */),
  "component---src-pages-blog-how-to-choose-the-perfect-website-js": () => import("./../../../src/pages/blog/how-to-choose-the-perfect-website.js" /* webpackChunkName: "component---src-pages-blog-how-to-choose-the-perfect-website-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-web-applications-vs-websites-js": () => import("./../../../src/pages/blog/web-applications-vs-websites.js" /* webpackChunkName: "component---src-pages-blog-web-applications-vs-websites-js" */),
  "component---src-pages-blog-what-is-website-responsiveness-js": () => import("./../../../src/pages/blog/what-is-website-responsiveness.js" /* webpackChunkName: "component---src-pages-blog-what-is-website-responsiveness-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-websites-js": () => import("./../../../src/pages/custom-websites.js" /* webpackChunkName: "component---src-pages-custom-websites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lms-websites-js": () => import("./../../../src/pages/lms-websites.js" /* webpackChunkName: "component---src-pages-lms-websites-js" */),
  "component---src-pages-logo-design-js": () => import("./../../../src/pages/logo-design.js" /* webpackChunkName: "component---src-pages-logo-design-js" */),
  "component---src-pages-online-stores-js": () => import("./../../../src/pages/online-stores.js" /* webpackChunkName: "component---src-pages-online-stores-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-web-applications-js": () => import("./../../../src/pages/web-applications.js" /* webpackChunkName: "component---src-pages-web-applications-js" */),
  "component---src-pages-wordpress-websites-js": () => import("./../../../src/pages/wordpress-websites.js" /* webpackChunkName: "component---src-pages-wordpress-websites-js" */)
}

